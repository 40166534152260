exports.components = {
  "component---src-components-coin-jsx": () => import("./../../../src/components/coin.jsx" /* webpackChunkName: "component---src-components-coin-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-buy-jsx": () => import("./../../../src/pages/buy.jsx" /* webpackChunkName: "component---src-pages-buy-jsx" */),
  "component---src-pages-buy-register-jsx": () => import("./../../../src/pages/buy/register.jsx" /* webpackChunkName: "component---src-pages-buy-register-jsx" */),
  "component---src-pages-complaints-handling-policy-jsx": () => import("./../../../src/pages/complaints-handling-policy.jsx" /* webpackChunkName: "component---src-pages-complaints-handling-policy-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-fraud-and-scam-awareness-policy-jsx": () => import("./../../../src/pages/fraud-and-scam-awareness-policy.jsx" /* webpackChunkName: "component---src-pages-fraud-and-scam-awareness-policy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kyc-aml-policy-jsx": () => import("./../../../src/pages/kyc-aml-policy.jsx" /* webpackChunkName: "component---src-pages-kyc-aml-policy-jsx" */),
  "component---src-pages-otc-terms-and-conditions-jsx": () => import("./../../../src/pages/otc-terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-otc-terms-and-conditions-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-restricted-jurisdictions-jsx": () => import("./../../../src/pages/restricted-jurisdictions.jsx" /* webpackChunkName: "component---src-pages-restricted-jurisdictions-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */)
}

